<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header ">
        <div class="card-title d-flex align-items-center justify-content-between">
          <h3 class="card-label">
            Job Dispatch
            <span class="d-block text-muted pt-2 font-size-sm">
              Dispatch job to driver
            </span>
          </h3>
        </div>

        <div class="col-md-2 my-1 mt-md-6 mb-md-0">
          <div class="input-icon">
            <input
              type="text"
              class="form-control"
              placeholder="Search..."
              @input="searchQuery"
              v-model="searchQueryText"
            />
            <span>
              <i class="flaticon2-search-1 text-muted"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
    <job-overview
      v-for="job in filteredList"
      :key="job.id"
      :job="job"
      :drivers="drivers"
      @driver-changed="driverAssign"
      @duplicate="duplicate"
      @open-job-detail="openJobDetail"
    />

    <job-full-details ref="modal" :jobid="jobid" />
  </div>
</template>

<script>
import { ref, onMounted } from "@vue/composition-api";
import { debounce } from "debounce";
import Fuse from "fuse.js";

import { listJobsForDispatch, dispatchTripToDriver, duplicateTrip } from "@/api/job.api";
import { getAllDriver } from "@/api/driver.api";
import JobOverview from "./components/JobOverview";
import JobFullDetails from "@/components/general/JobFullDetails";
import { useNotify } from "@/utils/notify";
import Swal from "sweetalert2";

export default {
  name: "DispatchJob",
  components: { JobOverview, JobFullDetails },
  setup(_, context) {
    const { notify } = useNotify(context);

    let fullList = [];
    const filteredList = ref([]);
    const drivers = ref([]);
    const searchQueryText = ref("");

    onMounted(() => {
      getJobForDispatch();
      getDriversForDispatch();
    });

    const getJobForDispatch = async () => {
      const response = await listJobsForDispatch();
      fullList = response.data;
      filteredList.value = fullList;
    };

    const getDriversForDispatch = async () => {
      const response = await getAllDriver();
      drivers.value = response.data;
    };

    const driverAssign = async (trip, id) => {
      try {
        await dispatchTripToDriver(trip, id);
        notify("Driver assigned", `The driver was assigned`);
      } catch (e) {
        console.error(e);
      }
    };

    const duplicate = async id => {
      try {
        Swal.fire({
          title: "Confirm?",
          text: "This will create a new trip with the same information, are you sure?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!"
        }).then(async result => {
          if (result.isConfirmed) {
            await duplicateTrip(id);
            notify("Trip created", `The trip was created.`);
            getJobForDispatch();
          }
        });
      } catch (e) {
        console.error(e);
      }
    };

    const modal = ref(null);
    const jobid = ref();
    const openJobDetail = job => {
      jobid.value = job;
      context.root.$nextTick(() => modal.value.$refs["jobdetails"].show());
    };

    const searchQuery = debounce(e => {
      if (!e.target.value) {
        filteredList.value = fullList;
      } else {
        const options = {
          minMatchCharLength: 3,
          keys: ["id", "client", "reference_no", "status", "trips.address", "trips.date", "trip.company"]
        };
        const fuse = new Fuse(fullList, options);
        const searchResult = fuse.search(e.target.value);
        filteredList.value = [];
        searchResult.forEach(result => {
          filteredList.value.push(result.item);
        });
      }
    }, 1000);

    return {
      filteredList,
      searchQuery,
      searchQueryText,

      drivers,
      driverAssign,
      duplicate,
      jobid,
      modal,
      openJobDetail
    };
  }
};
</script>

<style scoped>
.nested-td-no-border {
  border: none;
}
</style>
